<template>
  <v-card-text class="mt-3">
    <div class="d-flex align-center">
      <h4>เดือน</h4>
      <v-select :items="month" label="เลือกเดือน" hide-details outlined class="ms-3 me-3"></v-select>
      <h4>ปี</h4>
      <v-select :items="year" label="เลือกปี" hide-details outlined class="ms-3 me-3"></v-select>
    </div>
    <hr class="mt-5 mb-5" />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="employess"
      item-key="name"
      show-select
      class="elevation-1"
    >
    </v-data-table>
  </v-card-text>
</template>

<script>
export default {
  data() {
    return {
      month: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฏาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ],
      year: ['2560', '2561', '2562', '2563', '2564', '2565'],
      selected: [],
      headers: [
        {
          text: 'ชื่อ สกุล',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
      employess: [
        {
          name: 'นายชาติชาย ใจเย็น',
        },
        {
          name: 'นายสมหมาย หมายปอง',
        },
        {
          name: 'นายมุย หาใจ',
        },
        {
          name: 'นางสาวสมศรี ปองใจ',
        },
        {
          name: 'นางสาวศรีจันทร์ ใจดี',
        },
      ],
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
