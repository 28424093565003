var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('div',{staticClass:"d-flex test2"},[_c('div',{staticClass:"test"},[_c('div',[_c('div',{staticClass:"me-2 box_content box-1"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("พนักงาน")])]),_c('p',{staticClass:"mb-0 me-1"},[_vm._v(" "+_vm._s(this.$store.state.draft.amount + this.$store.state.notpaid.amount + this.$store.state.paid.amount + this.$store.state.makeSend.amount + this.$store.state.send.amount + this.$store.state.expire.amount + this.$store.state.cancel.amount)+" คน ")])])]),_c('div',{staticClass:"me-2 box_content box-2"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("เงินเดือน")])]),_c('p',{staticClass:"mb-0 me-1"},[_vm._v(_vm._s(this.$store.state.draft.amount)+" บาท")])]),_c('div',{staticClass:"me-2 box_content box-3"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("ประกันสังคม")])]),_c('p',{staticClass:"mb-0 me-1"},[_vm._v(_vm._s(this.$store.state.notpaid.amount)+" บาท")])]),_c('div',{staticClass:"me-2 box_content box-4"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("ภาษี")])]),_c('p',{staticClass:"mb-0 me-1"},[_vm._v(_vm._s(this.$store.state.notpaid.amount)+" บาท")])]),_c('div',{staticClass:"me-2 box_content box-5"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("ยอดเบิกจ่าย")])]),_c('p',{staticClass:"mb-0 me-1"},[_vm._v(_vm._s(this.$store.state.notpaid.amount)+" บาท")])])]),_c('div',[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("จ่ายเงินเดือน (พนักงานประจำ/ชั่วคราว)")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("รายชื่อพนักงาน")]),_c('v-card-text',[_c('ModalEmployess')],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("ปิด")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v("สร้าง")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("จ่ายเงินเดือน (จ่ายเงินเดือนพนักงานรายวัน)")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("รายชื่อพนักงานรายวัน")]),_c('v-card-text',[_c('ModalDay')],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("ปิด")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v("สร้าง")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("จ่ายเงินเดือน (ย้อนหลัง)")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("รายชื่อพนักงาน")]),_c('v-card-text',[_c('ModalBefore')],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("ปิด")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v("สร้าง")])],1)],1)]}}])})],1)]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"ค้นหา","type":"text","outlined":"","dense":"","hide-details":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-select',{attrs:{"prepend-inner-icon":_vm.icons.mdiFilter,"dense":"","outlined":"","hide-details":"","label":"ตัวกรอง","items":_vm.filter}})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-select',{attrs:{"prepend-inner-icon":_vm.icons.mdiFileDocument,"dense":"","hide-details":"","outlined":"","label":"ใบสำคัญจ่าย","items":_vm.filterBill}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"name","show-select":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }