<template>
  <v-card>
    <v-card-text>
      <!-- Description -->
      <!-- <h1>ใบสั่งซื้อ</h1> -->
      <div class="d-flex test2">
        <div class="test">
          <!-- พนักงาน -->
          <div>
            <div class="me-2 box_content box-1">
              <div class="d-flex justify-center">
                <p class="mb-0">พนักงาน</p>
              </div>
              <p class="mb-0 me-1">
                {{
                  this.$store.state.draft.amount +
                    this.$store.state.notpaid.amount +
                    this.$store.state.paid.amount +
                    this.$store.state.makeSend.amount +
                    this.$store.state.send.amount +
                    this.$store.state.expire.amount +
                    this.$store.state.cancel.amount
                }}
                คน
              </p>
            </div>
          </div>
          <!-- เงินเดือน -->
          <div class="me-2 box_content box-2">
            <div class="d-flex justify-center">
              <p class="mb-0">เงินเดือน</p>
            </div>
            <p class="mb-0 me-1">{{ this.$store.state.draft.amount }} บาท</p>
          </div>
          <!-- ประกันสังคม -->
          <div class="me-2 box_content box-3">
            <div class="d-flex justify-center">
              <p class="mb-0">ประกันสังคม</p>
            </div>
            <p class="mb-0 me-1">{{ this.$store.state.notpaid.amount }} บาท</p>
          </div>
          <!-- ภาษี -->
          <div class="me-2 box_content box-4">
            <div class="d-flex justify-center">
              <p class="mb-0">ภาษี</p>
            </div>
            <p class="mb-0 me-1">{{ this.$store.state.notpaid.amount }} บาท</p>
          </div>
          <!-- ยอดเบิกจ่าย -->
          <div class="me-2 box_content box-5">
            <div class="d-flex justify-center">
              <p class="mb-0">ยอดเบิกจ่าย</p>
            </div>
            <p class="mb-0 me-1">{{ this.$store.state.notpaid.amount }} บาท</p>
          </div>
        </div>
        <div>
          <!-- จ่ายเงินเดือนพนักงานประจำ/ชั่วคราาว -->
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">จ่ายเงินเดือน (พนักงานประจำ/ชั่วคราว)</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>รายชื่อพนักงาน</v-toolbar>
                <v-card-text>
                  <ModalEmployess />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">ปิด</v-btn>
                  <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <!-- จ่ายเงินเดือนพนักงานรายวัน -->
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-2">จ่ายเงินเดือน (จ่ายเงินเดือนพนักงานรายวัน)</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>รายชื่อพนักงานรายวัน</v-toolbar>
                <v-card-text>
                  <ModalDay />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">ปิด</v-btn>
                  <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <!-- จ่ายเงินเดือนย้อนหลัง -->
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-2">จ่ายเงินเดือน (ย้อนหลัง)</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>รายชื่อพนักงาน</v-toolbar>
                <v-card-text>
                  <ModalBefore />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">ปิด</v-btn>
                  <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFilter"
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            :items="filter"
          ></v-select>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFileDocument"
            dense
            hide-details
            outlined
            label="ใบสำคัญจ่าย"
            :items="filterBill"
          ></v-select>
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="desserts"
        :single-select="singleSelect"
        :search="search"
        item-key="name"
        show-select
        class="elevation-1 mt-5"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus,
  mdiMagnify,
  mdiTruck,
  mdiPrinterSearch,
  mdiPlusCircle,
  mdiFilter,
  mdiFileDocument,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ModalEmployess from './modalEmployess.vue'
import ModalDay from './modalDay.vue'
import ModalBefore from './modalBefore.vue'

export default {
  components: { DatePicker, ModalEmployess, ModalDay, ModalBefore },
  setup() {
    const filter = ['แบบร่าง', 'อนุมัติ', 'ยืนยันสั่งซื้อ', 'ยกเลิก', 'รับสินค้าบางส่วน', 'รับสินค้าครบแล้ว']
    const filterBill = ['ใบสำคัญจ่าย', 'ชื่อซัพพลายเออร์', 'สินค้า', 'ชื่อโปรเจกต์', 'โน็ต', 'เลขที่ใบกำกับภาษี']

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPlusCircle,
        mdiFilter,
        mdiFileDocument,
      },
      filter,
      filterBill,
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {
          text: 'No.',
          align: 'start',
          sortable: false,
          value: 'no',
        },
        { text: 'สถานะ', value: 'status' },
        { text: 'รอบจ่าย', value: 'pay_cycle' },
        { text: 'วันที่จ่าย', value: 'time_pay' },
        { text: 'ชื่อพนักงาน', value: 'nameEmp' },
        { text: 'ตำแหน่ง', value: 'position' },
        { text: 'เงินเดือน', value: 'salary' },
        { text: 'ยอดเบิกจ่าย', value: 'disbursement_amount' },
        { text: 'ยอดเบิกรวม', value: 'total_deducted' },
        { text: 'เงินได้สุทธิ', value: 'totalSalary' },
      ],
      desserts: [
        {
          id: 'wyw1',
          no: 'PR-0000000001',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'ต้นสน สุขใจ',
          position: 'Engineer',
          salary: 146000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 145250,
        },
        {
          id: 'wyw2',
          no: 'PR-0000000002',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'หมี แพนด้า',
          position: 'Sales Manager',
          salary: 100000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 99250,
        },
        {
          id: 'wy3',
          no: 'PR-000000003',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'สมศรี น้อย',
          position: 'Sales Manager',
          salary: 12000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 11350,
        },
        {
          id: 'wyw4',
          no: 'PR-0000000004',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'พ่อหนุ่ม ใจแข็ง',
          position: 'Project Manager',
          salary: 110000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 109250,
        },
        {
          id: 'wyw5',
          no: 'PR-0000000005',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'สมหมาย ใจเย็น',
          position: 'Programmer',
          salary: 100000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 99250,
        },
        {
          id: 'wyw6',
          no: 'PR-0000000006',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'มัดหมี่ อร่อย',
          position: 'HR',
          salary: 30000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 29250,
        },
        {
          id: 'wyw7',
          no: 'PR-0000000007',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'นอนน้อย นอนนะ',
          position: 'Programmer',
          salary: 100000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 99250,
        },
        {
          id: 'wyw8',
          no: 'PR-0000000008',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'สมชาย แข็งแกร่ง',
          position: 'Sales Manager',
          salary: 40000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 39250,
        },
        {
          id: 'wyw9',
          no: 'PR-0000000009',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'หมี แพนด้า',
          position: 'Engineer',
          salary: 100000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 99250,
        },
        {
          id: 'wyw10',
          no: 'PR-0000000010',
          status: 'รออนุมัติ',
          pay_cycle: 'ประจำเดือน 8/65',
          time_pay: '31/7/65',
          nameEmp: 'หมี แพนด้า',
          position: 'Programmer',
          salary: 100000,
          disbursement_amount: 0,
          total_deducted: 750,
          totalSalary: 99250,
        },
      ],
    }
  },
  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
  },
}
</script>

<style scoped>
.box_content {
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box_content.box-1 {
  background-color: #8dbc89;
  color: #ffffff;
  width: 8rem;
  height: 5rem;
}
.box_content.box-2 {
  background-color: dodgerblue;
  color: #ffffff;
  width: 8rem;
  height: 5rem;
}
.box_content.box-3 {
  color: #ffffff;
  background-color: coral;
  width: 8rem;
  height: 5rem;
}
.box_content.box-4 {
  color: #ffffff;
  background-color: coral;
  width: 8rem;
  height: 5rem;
}
.box_content.box-5 {
  color: #ffffff;
  background-color: coral;
  width: 8rem;
  height: 5rem;
}
.bg-header {
  background-color: #8dbc89;
}
.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}
.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}
.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}
.picker-res {
  width: 100% !important;
}
.test {
  display: flex;
}
.test2 {
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .test2 {
    display: grid !important;
    /* grid-template-columns: repeat(2, 1fr); */
    justify-content: center;
  }
  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-50%);
    left: 50%;
  }
}

@media screen and (max-width: 960px) {
  .test {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .test2 {
    display: grid !important;
    /* grid-template-columns: repeat(2, 1fr); */
    justify-content: center;
  }
  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-50%);
    left: 50%;
  }
}
</style>
