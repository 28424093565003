<template>
  <v-card-text class="mt-3 pb-0">
    <v-row class="mb-5">
      <!-- รอบจ่ายวันที่ -->
      <v-col sm="6" md="4" cols="12" class="d-flex align-center justify-end pb-0">
        <h4>รอบจ่ายวันที่</h4>
      </v-col>
      <v-col sm="6" md="8" cols="12" class="pb-0">
        <date-picker v-model="time3" range hide-details placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
      </v-col>
      <!-- จำนวนวันทำงาน -->
      <v-col sm="6" md="4" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
        <h4>จำนวนวันทำงาน</h4>
      </v-col>
      <v-col sm="6" md="8" cols="12" class="pt-0 pb-0">
        <v-radio-group v-model="radioGroup" hide-details>
          <v-radio v-for="n in workDay" :key="n" :label="`${n}`" :value="n"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="employess"
      item-key="name"
      show-select
      class="elevation-1"
    >
    </v-data-table>
    <div class="d-flex align-center mt-3">
      <v-checkbox v-model="checkbox" class="mt-0" hide-details></v-checkbox>
      <span>หักประกันสังคมสูงสุด 375 บาท</span>
    </div>
  </v-card-text>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  data() {
    return {
      workDay: ['คำนวณอัตโนมัติจากบันทึกเวลาเข้าออกงาน', 'กำหนดเอง'],
      selected: [],
      headers: [
        {
          text: 'ชื่อ สกุล',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'จำนวนวันทำงาน', value: 'workday' },
      ],
      employess: [
        {
          name: 'นายชาติชาย ใจเย็น',
          workday: 30,
        },
        {
          name: 'นายสมหมาย หมายปอง',
          workday: 27,
        },
        {
          name: 'นายมุย หาใจ',
          workday: 28,
        },
        {
          name: 'นางสาวสมศรี ปองใจ',
          workday: 30,
        },
        {
          name: 'นางสาวศรีจันทร์ ใจดี',
          workday: 30,
        },
      ],
      checkbox: false,
    }
  },
}
</script>

<style scoped></style>
